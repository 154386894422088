<script setup>
import { computed, onMounted, ref } from "vue";
import SearchBox from "@/components/layout/RE/policies/searchbox.vue";
import createNewPolicyModal from "./createNewPolicyModal.vue";
import { useStore } from "vuex";

const store = useStore();
const showModal = ref(false);
const loading = ref(false);
const tableHeaders = [
  { title: "Date", prop: "created_at", id: "date" },
  {
    title: "Roles",
    prop: "roles",
    id: "role",
  },
  {
    title: "Type",
    prop: "type",
    id: "type",
  },
  {
    title: "Desciption",
    prop: "detail",
    id: "description",
  },
  {
    title: "Actions",
    prop: "user",
    id: "actions",
  },
];
const allPolicies = computed(() => {
  return store.getters["RePolicies/getAllDifferentPolicies"];
});
onMounted(async () => {
  await store.dispatch("admin/loadRoles", 1);
  getPoilciiesFunc();
});

const getPoilciiesFunc = async () => {
  loading.value = true;
  try {
    await store.dispatch(
      "RePolicies/getAllDifferentPolicies",
      "employee-handbooks"
    );
    loading.value = false;
  } catch (error) {
    this.error = error.message || "Something went wrong!";
  }
  loading.value = false;
};

const getRoles = computed(() => {
  return store.getters["admin/getRoles"];
});
const rolesDefiner = (arr) => {
  return arr.map((item) => item.name).join(", ");
};
</script>
<template>
  <div class="container mx-auto px-4 mt-10">
    <div class="flex gap-4 items-center">
      <div class="w-96">
        <div
          @click="showModal = true"
          class="
            bg-white
            w-1/2
            py-4
            cursor-pointer
            hover:shadow-sm
            text-center
            mx-auto
            shadow-md
            rounded-xl
          "
        >
          <div class="">
            <span class="text-5xl material-icons"> post_add </span>
          </div>
          New Practice policy
        </div>
      </div>
      <div class="flex-grow">
        <SearchBox
          :secondaryOptions="getRoles"
          :layout="'horizontal'"
          class="w-full shadow-md px-5"
          title="Policy Search"
        />
      </div>
    </div>
  </div>
  <div class="w-11/12 mx-auto text-center text-grey my-10">
    <template v-if="!loading">
      <el-table
        header-row-class-name="bg-gray-200"
        header-cell-class-name="text-teal py-5 "
        :cell-style="{ padding: '10px' }"
        row-class-name="border-t"
        class="w-full rounded-xl overflow-hidden bg-gray-100"
        :data="allPolicies"
        style="width: 100%"
      >
        <el-table-column
          v-for="item in tableHeaders"
          :key="item.index"
          :prop="item.prop"
          :label="item.title"
        >
          <template #default="scope">
            <div class="" v-if="item.id === 'date'">
              {{ new Date(scope.row.created_at).toLocaleDateString("en-GB") }}
            </div>
            <div class="" v-if="item.id === 'role'">
              {{ rolesDefiner(scope.row.roles) }}
            </div>
            <div class="" v-if="item.id === 'type'">
              <span v-if="scope.row.url.slice(scope.row.url.length - 4) === 'docx' ">
              {{ scope.row.url.slice(scope.row.url.length - 4) }}
            </span>
            <span v-else>
              {{ scope.row.url.slice(scope.row.url.length - 3) }}
            </span>
            </div>
            <div class="" v-if="item.id === 'actions'">
              <router-link
                :to="{
                  name: 'hq-policy',
                  params: { policyId: scope.row.id },
                  query: { policy: scope.row.url },
                }"
              >
                <span class="material-icons"> more_horiz </span></router-link
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-else>
      <table-skeleton></table-skeleton>
    </template>
  </div>
  <createNewPolicyModal
    :secondaryOptions="getRoles"
    v-model:showModal="showModal"
    :title="'Add  New Practice policy'"
    :text="'employee-handbooks'"
    @success="getPoilciiesFunc()"
  />
</template>
